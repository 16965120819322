body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes rotating {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.MuiListItemSecondaryAction-root {
  position: relative !important;
  margin-left: 5px;
}

.MuiListItem-root a {
  align-items: center !important;
}

.MuiDrawer-root {
  margin-top: 10px;
}

.MuiDialog-paperWidthSm {
  max-width: 1600px !important;
}

nav li span.MuiTypography-body2 {
  margin-left: 16px !important;
}

@media (max-width: 900px) {
  .list > div > div > div > .MuiToolbar-root {
    background-color: transparent !important;
    padding: 0 !important;
  }
}

input[type='time'] {
  border: 1px solid #adadad;
  border-radius: 8px;
}
